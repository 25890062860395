<template>
  <div class="for-you-entry-detail">
    <PageContainer
      :title="entry.title"
      :back="actions.back"
      :has-shy-header="true"
      :ellipsis="true"
      v-if="entry?.content_format?.code === 'TEXT'"
    >
      <template v-slot:content>
        <div class="page-segment-content margin-bottom-lg">
          <template v-if="loading">
            <Skeleton
              class="margin-y-sm"
              width="480px"
              height="176px"
              borderRadius="8px"
            />
            <Skeleton
              class="margin-y-sm"
              width="480px"
              height="600px"
              borderRadius="8px"
            />
          </template>

          <template v-else>
            <!-- entry image cover -->
            <img
              class="for-you-entry-detail_cover margin-bottom"
              :src="entry.thumbnail"
              :alt="entry.title"
            />
            <!-- entry content -->
            <div
              class="for-you-entry-detail_text body"
              v-html="entry.content"
            ></div>
          </template>
        </div>
      </template>
    </PageContainer>
    <template v-else>
      <FlowResolver
        :flow="flow"
        :router="$router"
        :route="$route"
        :lang="$store.getters.language.lang"
        :callback="submit"
        v-if="!loadingFlow && flow"
      />
    </template>
  </div>
</template>

<script>
import {
  PageContainer,
  Skeleton,
  ForYou,
  Flow,
  FlowResolver
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'ForYouEntry',
  components: { PageContainer, Skeleton, FlowResolver },
  data () {
    const actions = {
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.push({
              path: this.$route.query.back
            })
          } else {
            this.$router.replace({
              name: 'ForYou'
            })
          }
        }
      }
    }

    return {
      entry: {},
      loading: false,
      actions,
      flow: null,
      loadingFlow: false
    }
  },
  created () {
    if (!this.group.length) {
      this.$store.dispatch('forYou/setup')
    }
    this.getEntryDetails()
  },
  methods: {
    getEntryDetails () {
      this.loading = true
      this.$loader.handleShowLoader({
        lang: this.$store.getters.language.lang,
        type: 'degrade',
        key: 'NO_MESSAGE'
      })
      ForYou.entryBySlug(this.$route.params.slug)
        .then((response) => {
          this.entry = response
          if (this.entry.content_format.code === 'FLOW') {
            this.loadingFlow = true
            this.getFlow()
          } else {
            this.$loader.handleRemoveLoader()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getFlow () {
      Flow.get(this.entry.content)
        .then((response) => (this.flow = response))
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingFlow = false
          this.$loader.handleRemoveLoader()
        })
    },
    submit () {
      console.log('final')
    }
  },
  computed: {
    ...mapGetters({
      group: 'forYou/group'
    })
  },
  destroyed () {
    this.$loader.handleRemoveLoader()
  }
}
</script>

<style lang="sass" scoped>
.for-you-entry-detail
  &_cover
    object-position: center
    object-fit: cover
    width: 100%
    height: 176px
  &_text
    padding: 0 16px

@media (min-width: 480px)
  .for-you-entry-detail
    &_cover
      border-radius: 8px
    &_text
      padding: 0px
</style>
